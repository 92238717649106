/*---------------- BORDER RADIUS MIXIN */

.border-radius (@corner; @radius: 5px) {
    & when (isstring(@corner)), (iskeyword(@corner)) {
        & when (@corner = 'top-left'), (@corner = 'top left'), (@corner = top-left) {
            border-top-left-radius: @radius;
        }

        & when (@corner = 'top-right'), (@corner = 'top right'), (@corner = top-right) {
            border-top-right-radius: @radius;
        }

        & when (@corner = 'bottom-left'), (@corner = 'bottom left'), (@corner = bottom-left) {
            border-bottom-left-radius: @radius;
        }

        & when (@corner = 'bottom-right'), (@corner = 'bottom right'), (@corner = bottom-right) {
            border-bottom-right-radius: @radius;
        }

        & when (@corner = 'all'), (@corner = all) {
            border-radius: @radius;
        }
    }

    & when (ispixel(@corner)), (isem(@corner)), (ispercentage(@corner)) {
        border-radius: @corner;
    }
}

/*---------------- GRADIENT MIXIN */

.gradient (@colour1; @colour2; @type: 'vert'; @fallback_colour: false) {
    & when (iscolor(@fallback_colour)) {
        background: @fallback_colour;
    }
    & when (iskeyword(@fallback_colour)) {
        background: mix(@colour1, @colour2, 50%);
    }
    & when (isstring(@type)), (iskeyword(@type)) {
        & when (@type = 'vert'), (@type = 'vertical'), (@type = vert), (@type = vertical) {
            background: -webkit-linear-gradient(top, @colour1 0%, @colour2 100%);
            background: linear-gradient(to bottom, @colour1 0%, @colour2 100%);
        }

        & when (@type = 'horiz'), (@type = 'horizontal'), (@type = horiz), (@type = horizontal) {
            background: -webkit-linear-gradient(left, @colour1 0%, @colour2 100%);
            background: linear-gradient(to right, @colour1 0%, @colour2 100%);
        }

        & when (@type = 'radial'), (@type = radial) {
            background: -webkit-radial-gradient(center, ellipse cover, @colour1 0%, @colour2 100%);
            background: radial-gradient(ellipse at center, @colour1 0%, @colour2 100%);
        }
    }
}

/*---------------- MISCELLANEOUS FUNCTIONS */

/* Removes border radii and box and text shadows */
.flatten () {
    .border-radius(0px);
    box-shadow: none;
    text-shadow: none;
}

/* Shortcut to insert a 1px border around an element */
.bordered (@top: #000000; @right: false; @bottom: false; @left: false) {
    & when (iskeyword(@right)) {
        border: 1px solid @top;
    }

    & when (iscolor(@right)) {
        border-top: 1px solid @top;
        border-right: 1px solid @right;
        border-bottom: 1px solid @bottom;
        border-left: 1px solid @left;
    }
}

/* Prevents user selection (not supported in IE9) */
.no-select () {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Allows text selection */
.allow-select () {
    -webkit-touch-callout: text;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.retina (@path, @w: auto, @h: auto) {
    background-image: url(@path);
    @retina_path: ~`@{path}.replace(/\.\w+$/, function(match) { return "@2x" + match; })`;

    @media (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx) {
        background-image: url("@{retina_path}");
        background-size: @w @h;
    }
}

.collapsible-table() {
    margin: 0px ((@grid-gutter-size / 2) * -1) @content-size;
    display: block !important;
    width: auto !important;

    tbody, tr {
        display: block !important;
        width: 100% !important;

        td, th {
            display: block !important;
            width: 100% !important;
            padding: 5px (@grid-gutter-size / 2);

            &:first-child,
            &:last-child {
                padding: 5px (@grid-gutter-size / 2);
            }
        }
    }
}
