@keyframes alert-slidedown {
    0% {
        top: -150px;
    }
    100% {
        top: 0px;
    }
}

@-webkit-keyframes alert-slidedown {
    0% {
        top: -150px;
    }
    100% {
        top: 0px;
    }
}

@keyframes alert-slideup {
    0% {
        top: 0px;
    }
    100% {
        top: -150px;
    }
}

@-webkit-keyframes alert-slideup {
    0% {
        top: 0px;
    }
    100% {
        top: -150px;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes field-error-margin {
    0% {
        margin-bottom: 20px;
    }

    100% {
        margin-bottom: 4px;
    }
}

@-webkit-keyframes field-error-margin {
    0% {
        margin-bottom: 20px;
    }

    100% {
        margin-bottom: 4px;
    }
}

@keyframes show-error {
    0% {
        height: 0px;
    }

    100% {
        height: 16px;
    }
}

@-webkit-keyframes show-error {
    0% {
        height: 0px;
    }

    100% {
        height: 16px;
    }
}

@keyframes modal-open {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@-webkit-keyframes modal-open {
    0% {
        opacity: 0;
        -webkit-transform: scale(1.1);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
    }
}

@keyframes modal-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes modal-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
