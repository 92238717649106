header {
    position: relative;
    background: @header-back-colour;

    & when (@header-use-background-image = true) {
        background-image: ~"url(/img/@{header-background-image})";
        background-position: center center;
        background-size: cover;
    }

    color: @header-fore-colour;
    height: @header-height;
    margin-bottom: @header-bottom-spacing;

    #logo {
        height: @header-height;
        width: 100%;

        & when (@navbar-position = 'top') or (@navbar-position = 'bottom') {
            height: @header-height - @navbar-height;
        }

        & when (@navbar-position = 'top') {
            position: absolute;
            top: @navbar-height;
            left: 0;
        }

        div.container {
            height: 100%;

            h1 {
                position: relative;
                height: 100%;
                width: auto;
                margin: 0px;
                font-size: 3.5em;
                padding: (@header-vertical-gutter) 0;

                a {
                    display: block;
                    height: 100%;
                    line-height: 100%;
                    width: auto;

                    color: @header-fore-colour;
                    text-decoration: none;
                }

                & when (@header-logo-position = 'center') {
                    & when (@header-use-logo = false) {
                        text-align: center;
                    }

                    & when (@header-use-logo = true) {
                        left: 50%;
                        margin-left: (@header-logo-width / 2) * -1;
                        width: 100%;
                        max-width: @header-logo-width;
                    }
                }

                & when (@header-logo-position = 'centre') {
                    & when (@header-use-logo = false) {
                        text-align: center;
                    }

                    & when (@header-use-logo = true) {
                        left: 50%;
                        margin-left: (@header-logo-width / 2) * -1;
                        width: 100%;
                        max-width: @header-logo-width;
                    }
                }

                & when (@header-logo-position = 'right') {
                    & when (@header-use-logo = false) {
                        text-align: right;
                    }

                    & when (@header-use-logo = true) {
                        left: 100%;
                        margin-left: (@header-logo-width * -1);
                    }
                }

                & when (@header-use-logo = true) {
                    a {
                        height: 100%;
                        width: 100%;
                        max-width: @header-logo-width;

                        background: ~"url(/image/?image=@{header-logo-image}&mode=fit&w=@{header-logo-width}&h=@{header-logo-height}) no-repeat center center";
                        background-size: contain;

                        @media (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx) {
                            @header-logo-width-2x: @header-logo-width * 2;
                            @header-logo-height-2x: @header-logo-height * 2;
                            background: ~"url(/image/?image=@{header-logo-image}&mode=fit&w=@{header-logo-width-2x}&h=@{header-logo-height-2x}) no-repeat center center";
                            background-size: contain;
                        }

                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

body.home-page {
    header {
        & when (@home-page-jumbo = true) {
            height: @home-page-header-height;

            #logo {
                height: @home-page-header-height;

                & when (@navbar-position = 'top') or (@navbar-position = 'bottom') {
                    height: @home-page-header-height - @navbar-height;
                }

                h1 {
                    padding: (@home-page-header-vertical-gutter) 0;

                    & when (@header-use-logo = true) {
                        width: 100%;
                        max-width: @home-page-header-logo-width;

                        & when (@header-logo-position = 'center') {
                            left: 50%;
                            margin-left: (@home-page-header-logo-width / 2) * -1;
                            width: 100%;
                            max-width: @home-page-header-logo-width;
                        }

                        & when (@header-logo-position = 'centre') {
                            left: 50%;
                            margin-left: (@home-page-header-logo-width / 2) * -1;
                            width: 100%;
                            max-width: @home-page-header-logo-width;
                        }
                    }

                    & when (@header-logo-position = 'right') {
                        & when (@header-use-logo = false) {
                            text-align: right;
                        }

                        & when (@header-use-logo = true) {
                            margin-left: (@home-page-header-logo-width * -1);
                        }
                    }

                    a {
                        & when (@header-use-logo = true) {
                            background: ~"url(/image/?image=@{header-logo-image}&mode=fit&w=@{home-page-header-logo-width}&h=@{home-page-header-logo-height}) no-repeat center center";
                            width: 100%;
                            max-width: @home-page-header-logo-width;

                            @media (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx) {
                                @home-page-header-logo-width-2x: @home-page-header-logo-width * 2;
                                @home-page-header-logo-height-2x: @home-page-header-logo-height * 2;
                                background: ~"url(/image/?image=@{header-logo-image}&mode=fit&w=@{home-page-header-logo-width-2x}&h=@{home-page-header-logo-height-2x}) no-repeat center center";
                                background-size: contain;
                            }
                        }
                    }
                }
            }
        }
    }
}
