#outdated {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 170px;
    text-align: center;
    text-transform: uppercase;
    z-index: 10001;
    background-color: #f25648;
    color: #ffffff;
}
* html #outdated {
    position: absolute;
}
#outdated h6 {
    font-size: 25px;
    line-height: 25px;
    margin: 30px 0 10px;
}
#outdated p {
    font-size: 12px;
    line-height: 12px;
    margin: 0;
}
#outdated #btnUpdateBrowser {
    display: block;
    position: relative;
    padding: 10px 20px;
    margin: 30px auto 0;
    width: 230px;
    /*need for IE*/

    color: #ffffff;
    text-decoration: none;
    border: 2px solid #ffffff;
    cursor: pointer;
}
#outdated #btnUpdateBrowser:hover {
    color: #f25648;
    background-color: #ffffff;
}
#outdated .last {
    position: absolute;
    top: 10px;
    right: 25px;
    width: 20px;
    height: 20px;
}
#outdated .last[dir='rtl'] {
    right: auto !important;
    left: 25px !important;
}
#outdated #btnCloseUpdateBrowser {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #ffffff;
    font-size: 36px;
    line-height: 36px;
}
