.resize(@val) {
  resize: @val;
  -moz-resize: @val;
  -webkit-resize: @val;
}

.opacity (@opacity: 0.5) {
	-webkit-opacity: 	@opacity;
	-moz-opacity: 		@opacity;
	opacity: 		@opacity;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.transition(@transition) {
	-webkit-transition: @transition;
	-moz-transition:    @transition;
	-ms-transition:     @transition;
	-o-transition:      @transition;
}

.transform(@string){
	-webkit-transform: @string;
	-moz-transform: 	 @string;
	-ms-transform: 		 @string;
	-o-transform: 		 @string;
}

.box-sizing (@type: border-box) {
	-webkit-box-sizing: @type;
	-moz-box-sizing:    @type;
	box-sizing:         @type;
}

.border-radius (@radius: 0) {
  border-radius: @radius;
  -moz-border-radius: @radius;
  -webkit-border-radius: @radius;

  -moz-background-clip:    padding;
	-webkit-background-clip: padding-box;
	background-clip:         padding-box;
}

.user-select(@select) {
  user-select: @select;
  -o-user-select:@select;
  -moz-user-select: @select;
  -khtml-user-select: @select;
  -webkit-user-select: @select;
  -ms-user-select: @select;
}

.box-shadow(@shadow) {
  -webkit-box-shadow: @shadow;
  -moz-box-shadow: @shadow;
  box-shadow: @shadow;
}

.material-box-shadow (@level: 1, @direction: 1) when (@level = 0) {
  .box-shadow(none);
}

.material-box-shadow (@level: 1, @direction: 1) when (@level = 1) {
  @shadow: 0 (@direction * 1px) 3px rgba(0,0,0,0.12), 0 (@direction * 1px) 2px rgba(0,0,0,0.24);
  .box-shadow(@shadow);
}

.material-box-shadow (@level: 1, @direction: 1) when (@level = 2) {
  @shadow: 0 (@direction * 3px) 6px rgba(0,0,0,0.16), 0 (@direction * 2px) 4px rgba(0,0,0,0.23);
  .box-shadow(@shadow);
}

.material-box-shadow (@level: 1, @direction: 1) when (@level = 3) {
  @shadow: 0 (@direction * 5px) 8px rgba(0,0,0,0.19), 0 (@direction * 4px) 6px rgba(0,0,0,0.23);
  .box-shadow(@shadow);
}

.material-box-shadow (@level: 1, @direction: 1) when (@level = 4) {
  @shadow: 0 (@direction * 8px) 12px rgba(0,0,0,0.25), 0 (@direction * 6px) 6px rgba(0,0,0,0.22);
  .box-shadow(@shadow);
}

.material-box-shadow (@level: 1, @direction: 1) when (@level >= 5) {
  @shadow: 0 (@direction * 10px) 16px rgba(0,0,0,0.30), 0 (@direction * 6px) 8px rgba(0,0,0,0.22);
  .box-shadow(@shadow);
}

.clearfix {
  &::after {
    clear: both;
    display: block;
    content: "";
  }
}

.column-count(@count) {
  column-count: @count;
  -moz-column-count: @count;
  -webkit-column-count: @count;
}

.column-gap(@gap) {
  column-gap: @gap;
  -moz-column-gap: @gap;
  -webkit-column-gap: @gap;
}

.animation (@val) {
	-webkit-animation: @val;
  -moz-animation: @val;
  -o-animation: @val;
  animation: @val;
}

.handler-size(@size) {
  .fr-handler {
    width: @size;
    height: @size;

    &.fr-hnw {
      left: (-@size / 2);
      top: (-@size / 2);
    }

    &.fr-hne {
      right: (-@size / 2);
      top: (-@size / 2);
    }

    &.fr-hsw {
      left: (-@size / 2);
      bottom: (-@size / 2);
    }

    &.fr-hse {
      right: (-@size / 2);
      bottom: (-@size / 2);
    }
  }
}