body {
    & when (@navbar-position = 'fixed-top') {
        padding-top: @navbar-height;
    }

    & when (@navbar-position = 'fixed-bottom') {
        padding-bottom: @navbar-height;
    }
}

#main-nav {
    width: 100%;
    background: @navbar-back-colour;

    & when (@navbar-position = 'fixed-top') {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1999;
    }

    & when (@navbar-position = 'fixed-bottom') {
        position: fixed;
        bottom: 0px;
        left: 0px;
        z-index: 1999;
        margin-bottom: 0;

        .navbar .navbar-nav li.dropdown .dropdown-menu {
            top: auto;
            bottom: 100%;
        }
    }

    & when (@navbar-position = 'top') {
        position: absolute;
        top: 0;
        left: 0;
    }

    .navbar {
        position: relative;
        border: none;
        background: none;
        height: @navbar-height;
        margin-bottom: 0px;
        font-size: @navbar-font-size;
        min-height: 18px;

        .navbar-brand {
            color: @navbar-fore-colour;
            background: @navbar-back-colour;
            text-decoration: none;
            font-size: inherit;
            padding: 0px @navbar-item-padding;
            height: @navbar-height;
            line-height: @navbar-height;

            & when (@show-brand = false) {
                display: none;
            }

            &:hover {
                color: @navbar-hover-fore-colour;
                background: @navbar-hover-back-colour;
            }

            &.navbar-brand-active {
                color: @navbar-active-fore-colour;
                background: @navbar-active-back-colour;

                &:hover {
                    color: @navbar-hover-fore-colour;
                    background: @navbar-hover-back-colour;
                }
            }
        }

        .navbar-nav {
            height: @navbar-height;

            li {
                a {
                    color: @navbar-fore-colour;
                    background: @navbar-back-colour;
                    text-decoration: none;
                    padding: 0px @navbar-item-padding;
                    line-height: @navbar-height;

                    &:hover {
                        color: @navbar-hover-fore-colour;
                        background: @navbar-hover-back-colour;
                    }
                }

                &.active {
                    > a {
                        color: @navbar-active-fore-colour;
                        background: @navbar-active-back-colour;

                        &:hover {
                            color: @navbar-hover-fore-colour;
                            background: @navbar-hover-back-colour;
                        }
                    }
                }
            }

            li.dropdown {
                .dropdown-menu {
                    padding: 0px;
                    .border-radius(0px);
                    border-top: none;

                    li {
                        a {
                            color: @dropdown-fore-colour;
                            background: @dropdown-back-colour;

                            &:hover {
                                color: @navbar-hover-fore-colour;
                                background: @navbar-hover-back-colour;
                            }
                        }

                        &.active {
                            > a {
                                color: @navbar-active-fore-colour;
                                background: @navbar-active-back-colour;

                                &:hover {
                                    color: @navbar-hover-fore-colour;
                                    background: @navbar-hover-back-colour;
                                }
                            }
                        }
                    }
                }
            }
        }

        .navbar-toggle {
            position: absolute;
            top: 10%;
            right: 10px;
            height: 80%;

            width: 40px;
            margin: 0px;
            padding: 0px;
            background: @navbar-hover-back-colour;
            border: 0px;

            .icon-bar {
                background: @navbar-hover-fore-colour;
                margin: 4px auto;
            }
        }
    }

    @media screen and (max-width: @mobile-menu-switchover) {
        .navbar {
            overflow: visible;

            .navbar-collapse {
                overflow: hidden;
                position: absolute;
                width: 100%;
                height: inherit;
                top: @navbar-height;
                border-top: 0;
                transition: height 0.35s ease 0s;

                &.in {
                    overflow: visible;
                }

                .navbar-nav {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    height: auto;
                    width: 100%;
                    margin: 0px;

                    z-index: 1000;
                }
            }
        }
    }
}

body.cms-menu-visible {
    #main-nav {
        & when (@navbar-position = 'fixed-top') {
            top: 50px;
        }
    }
}

.submenu {
    margin-top: 40px;

    li {
        a {
            text-decoration: none;
            background: @submenu-back-colour;
            color: @submenu-fore-colour;

            &:hover {
                background: @submenu-hover-back-colour;
                color: @submenu-hover-fore-colour;
            }
        }

        &.active {
            a {
                background: @submenu-active-back-colour;
                color: @submenu-active-fore-colour;

                &:hover {
                    background: @submenu-hover-back-colour;
                    color: @submenu-hover-fore-colour;
                }
            }
        }
    }
}
