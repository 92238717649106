// Include default ABWeb CMS public styles
@import '../../../../../../webroot/assets/src/less/default.less';
// Custom variables
@import (optional) 'variables.less';
// Animations
@import (optional) 'animations.less';

/* -- Add site-specific styling below -- */

header {
    border-bottom: 3px solid #f94744;

    #logo {
        height: 95px;
    }

    .container {
        position: relative;
    }

    #main-nav {
        position: absolute;
        top: 58px;
        left: 315px;
        width: 855px;

        background-image: url('/img/right-nav-edge.png');
        background-repeat: no-repeat;
        background-position: top right;

        .navbar {
            .navbar-brand {
                padding-left: 36px;

                font-family: 'PT Sans', sans-serif;

                background-image: url('/img/left-nav-edge.png');
                background-repeat: no-repeat;
                background-position: top left;

                &:hover {
                    background-image: url('/img/left-nav-edge.png');
                    background-repeat: no-repeat;
                    background-position: center left;
                }

                &.navbar-brand-active {
                    background-image: url('/img/left-nav-edge.png');
                    background-repeat: no-repeat;
                    background-position: bottom left;

                    &:hover {
                        background-image: url('/img/left-nav-edge.png');
                        background-repeat: no-repeat;
                        background-position: center left;
                    }
                }
            }

            .navbar-nav {
                & > li {
                    & > a {
                        font-family: 'PT Sans', sans-serif;
                        padding-left: 12px;

                        &:before {
                            display: block;
                            width: 20px;
                            height: @navbar-height;
                            position: absolute;
                            left: -20px;

                            content: " ";
                            background-color: transparent;
                            background-image: url('/img/nav-edge.png');
                            background-repeat: no-repeat;
                            background-position: top left;
                        }

                        &:hover {
                            &:before {
                                background-position: center left;
                            }
                        }
                    }

                    &.active {
                        & > a {
                            &:before {
                                background-position: bottom left;
                            }

                            &:hover {
                                &:before {
                                    background-position: center left;
                                }
                            }
                        }
                    }
                }
            }

            .navbar-toggle {
                right: 30px;
            }

            div.end-item {
                position: relative;
                display: inline-block;
                width: 20px;
                height: @navbar-height;
                margin-left: -20px;

                z-index: 10000;
                background-image: url('/img/nav-edge.png');
                background-repeat: no-repeat;
                background-position: top left;
            }
        }

        @media screen and (max-width: 767px) {
            width: 220px;
            .navbar {
                .navbar-nav {
                    & > li {
                        & > a {
                            &:before {
                                background: none;
                            }
                        }
                    }
                }

                div.end-item {
                    display: none;
                }
            }
        }
    }

    .social-info {
        position: absolute;
        top: 12px;
        left: 335px;
        height: 35px;
        width: 130px;

        a {
            font-size: 32px;
            margin-right: 8px;

            &.facebook-link {
                color: #3C5A98;
            }

            &.google-link {
                color: #DB4B39;
            }

            &.instagram-link {
                color: #9A6950;
            }

            &:hover {
                color: #000000;
            }
        }
    }

    .contact-info {
        position: absolute;
        top: 12px;
        right: 0px;
        height: 35px;
        width: 400px;
        padding-right: 10px;

        text-align: right;

        .glyphicon {
            color: #E43F3E;
            font-size: 2.2em;
        }

        .tel {
            span {
                display: block;
                color: #4B4545;
                font-size: 0.9em;
            }

            strong {
                display: block;
                margin-top: -6px;
                font-size: 1.4em;

                a {
                    color: #E43F3E;
                    text-decoration: none;

                    &:hover {
                        color: #000000;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        & {
            border-bottom: none;
            height: auto;
        }

        .container {
            padding: 0;
        }

        h1 {
            a {
                margin: 0 auto;
            }
        }

        .social-info {
            position: relative;
            top: 0;
            right: 10px;
            margin-top: 5px;
            margin-bottom: 10px;
            width: 100%;
            left: auto;
            text-align: right;
        }

        .contact-info {
            left: 0px;
            right: auto;
            top: 107px;
            width: 100px;
        }

        #main-nav {
            position: relative;
            top: auto;
            left: auto;
            width: 100%;
            background-image: none;

            .navbar-brand {
                background-image: none !important;
            }
        }
    }
}

footer {
    position: relative;

    .top-footer {
        padding-top: 15px;

        h4 {
            font-size: 1.1em;
            color: #C5C5C5;
            font-weight: bold;
        }

        ul.footer-nav {
            padding: 0;

            li {
                list-style: none;

                a {
                    color: #c0c0c0;
                    text-decoration: none;

                    &:hover {
                        color: #ffffff;
                    }
                }

                &.active {
                    a {
                        color: #E43F3E;
                    }
                }
            }
        }

        .contact-footer {
            .glyphicon {
                color: #E43F3E;
                font-size: 2.2em;
            }

            .tel {
                span {
                    display: block;
                    color: #E9E9E9;
                    font-size: 1em;
                }

                strong {
                    display: block;
                    margin-top: -6px;
                    font-size: 1.5em;

                    a {
                        color: #E43F3E;
                        text-decoration: none;

                        &:hover {
                            color: #000000;
                        }
                    }
                }
            }
        }

        .social-footer {
            margin-top: 10px;
            padding-right: 10px;
            text-align: right;

            a {
                font-size: 28px;
                margin-right: 8px;

                &.facebook-link {
                    color: #3C5A98;
                }

                &.google-link {
                    color: #DB4B39;
                }

                &.instagram-link {
                    color: #9A6950;
                }

                &:hover {
                    color: #ffffff;
                }
            }
        }
    }

    .bottom-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 48px;
        width: 100%;

        background: #1E1E1E;

        div.col-xs-12 {
            line-height: 48px;
            background: none;

            a {
                color: #ffffff;
                text-decoration: none;
            }
        }
    }

    @media screen and (max-width: 767px) {
        & {
            font-size: 0.9em;

            .top-footer {
                .contact-footer {
                    .tel {
                        strong {
                            display: block;
                            margin-left: -15px;
                            font-size: 1.3em;
                            margin-top: 5px;
                        }
                    }
                }
            }

            .bottom-footer {
                div.col-xs-12 {
                    padding-top: 10px;
                    line-height: 1.1em;
                }
            }

        }
    }
}
/*------------------ HOME PAGE */

#carousel-home {
    position: relative;
    margin-top: -50px;

    .carousel-inner {
        background: url('/img/slideshow/slider-background.jpg') no-repeat center bottom;
        background-size: cover;
        height: 516px;

        @media screen and (max-width: 1800px) {
            & {
                height: 460px !important;
            }
        }

        @media screen and (max-width: 1500px) {
            & {
                height: 400px !important;
            }
        }

        @media screen and (max-width: 1500px) {
            & {
                height: 380px !important;
            }
        }

        @media screen and (max-width: 1300px) {
            & {
                height: 270px !important;
            }
        }

        img {
            max-width: none !important;
            height: 516px !important;

            // @media screen and (max-width: 2100px) {
            //     & {
            //         margin-left: -150px;
            //     }
            // }

            @media screen and (max-width: 1800px) {
                & {
                    margin-left: 0;
                    height: 460px !important;
                }
            }

            @media screen and (max-width: 1500px) {
                & {
                    margin-left: 0;
                    height: 400px !important;
                }
            }

            @media screen and (max-width: 1400px) {
                & {
                    margin-left: 0;
                    height: 380px !important;
                }
            }

            @media screen and (max-width: 1300px) {
                & {
                    margin-left: 0;
                    height: 270px !important;
                }
            }
        }
    }

    .carousel-caption {
        bottom: 0px;
        left: 0px;
        right: auto;
        width: 40%;
        padding: 15px 20px 15px 15%;

        text-align: right;
        font-family: 'PT Sans', sans-serif;
        font-size: 36px;
        line-height: 36px;
        text-transform: uppercase;
        background: rgba(0, 0, 0, 0.6);

        span {
            color: #F94744;
        }
    }

    .carousel-indicators {
        bottom: -35px;

        @media screen and (max-width: 1300px) and (min-width: 980px) {
            & {
                margin-left: -540px;
            }
        }

        li {
            border: none;
            background: #4A4545;
            margin: 0px 6px;
            width: 12px;
            height: 12px;

            &.active {
                margin: 0px 6px;
                border: none;
                background: #F94744;
                width: 12px;
                height: 12px;
            }
        }
    }

    .booking-form {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        padding-top: 20px;

        .container {
            height: 100%;
        }

        iframe {
            float: right;
            border: 0;
            background: #eee;
            padding-top: 15px;
            height: 475px;
            width: 450px;
            box-shadow: 0px 1px 12px 1px rgba(50, 50, 50, 0.45);

            @media screen and (max-width: 1900px) {
                & {
                    margin-right: -100px;
                }
            }

            @media screen and (max-width: 1500px) {
                & {
                    margin-right: -100px;
                }
            }

            @media screen and (max-width: 1400px) {
                & {
                    margin-right: -50px;
                }
            }

            @media screen and (max-width: 1300px) {
                & {
                    margin-right: 0px;
                }
            }

            @media (max-width: 992px) {
                width: 100%;
            }
        }
    }
}

.booking-form-xs-sm iframe {
    border: 0;
    background: #eee;
    height: 480px;
    width: 100%;
    margin-top: 30px;
    padding-top: 15px;
}

/*------------------ BUTTONS */
.btn {
    border: 1px solid #ffffff;

    &:hover,
    &:focus {
        border: 1px solid #ffffff !important;
    }

    &.btn-primary {
        background: #5f5ecc;
        box-shadow: 0px 0px 0px 1px #5f5ecc;

        &:hover {
            background: lighten(#5f5ecc, 10%);
        }
    }

    &.btn-success {
        background: #48952E;
        box-shadow: 0px 0px 0px 1px #48952E;

        &:hover {
            background: lighten(#48952E, 10%);
        }
    }

    &.btn-warning {
        background: #E5902E;
        box-shadow: 0px 0px 0px 1px #E5902E;

        &:hover {
            background: lighten(#E5902E, 10%);
        }
    }

    &.btn-danger {
        background: #BD1C1C;
        box-shadow: 0px 0px 0px 1px #BD1C1C;

        &:hover {
            background: lighten(#BD1C1C, 10%);
        }
    }

    &.btn-info {
        background: #31B0D5;
        box-shadow: 0px 0px 0px 1px #31B0D5;

        &:hover {
            background: lighten(#31B0D5, 10%);
        }
    }
}

div.page-header {
    border-bottom: 3px double #c9c9c9;
    margin: 20px 0px;

    h1, h2, h3, h4, h5 {
        position: relative;
        bottom: -28px;
        display: inline-block;
        padding: 0px 30px 0px 0px;

        background: #fff;
    }
}

div.about-us-banner {
    width: 100%;
    height: 440px;
    margin-bottom: -25px;

    background: url('/img/about-us-banner.jpg') no-repeat top center;
    background-size: cover;

    div.about-us-content {
        margin-top: 85px;
        padding: 30px;

        background: rgba(0, 0, 0, 0.85);
        color: #fff;
        font-size: 1.3em;
        font-weight: 400;
    }

    h3 {
        padding: 10px;
        width: 291px;
        background: rgba(0, 0, 0, 0.85);
        color: #ffffff;
    }

    .friends {
        .item {
            padding: 0px 0px 10px 0px;

            img {
                box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
            }
        }
    }

    @media screen and (max-width: 767px) {
        & {
            font-size: 0.8em;

            div.about-us-content {
                margin-top: 55px;
            }
        }
    }
}

div.current-deals-list {
    margin: 60px 0px 60px;

    @media screen and (min-width: 980px) and (max-width: 1300px) {
        & {
            margin-top: 140px !important;
        }
    }

    div.article-container {
        position: relative;
        .bordered(#CCCCCC);
        text-align: center;
        height: 320px;
        margin-bottom: 30px;

        div.article-image {
            width: 100%;
            min-height: 140px;

            a {
                img {
                    margin: 0 auto;
                }
            }
        }

        div.article-listing-details {
            h3 {
                padding: 0px 10px;

                a {
                    color: #F94745;
                    text-decoration: none;

                    &:hover {
                        color: #000;
                    }
                }
            }

            div.article-text {
                padding: 5px 20px;
            }
        }

        a.read-more {
            position: absolute;
            display: inline-block;
            bottom: -15px;
            left: 50%;
            margin-left: -48px;
        }
    }
}

div.article-list-details {
    img {
        float: right;
        margin: -40px 10px 10px;
    }
}

.gallery-slider {
    margin: 0 auto;
    width: 100%;

    .slick-slide {
        opacity: 0.3;
        overflow: hidden;
        margin-right: 10px;
        cursor: pointer;

        &.slick-center {
            opacity: 1;
        }
    }
}

.gallery-image {
    .image {
        text-align: center;
        margin: 0 auto;
    }
}

@import 'slick.less';
@import '../../../../../../Plugin/Articles/webroot/assets/less/style.less';

.article-grid{margin-bottom:20px}.article-grid .article-grid-container{background:#fafafa;border:1px solid #e6e6e6;min-height:300px;padding:10px}.article-grid .article-grid-image{background:#000;margin:-11px -11px 0 -11px}.article-grid .article-grid-image img{margin:0 auto}.article-grid .article-grid-actions{text-align:right;margin-top:5px}.article-grid h3{margin-bottom:5px}.article-grid h3 a{text-decoration:none}.article-grid .article-date{margin-bottom:8px;font-size:.9em}@media (min-width:992px){.article-grid{margin-right:1.33%;width:24%}.grid-fourth{margin-right:0}}@media (min-width:768px) and (max-width:991px){.article-grid{margin-right:1%;width:49%}.grid-second,.grid-fourth{margin-right:0;margin-left:1%}}.article-list{margin-bottom:10px}.article-list .article-container{background:#fafafa;border:1px solid #e6e6e6;padding:10px}.article-list .article-list-image img{border:1px solid #e6e6e6;padding:1px;margin:0 auto}.article-list h3{margin-top:10px;margin-bottom:5px}.article-list h3 a{text-decoration:none}.article-list .article-date{margin-bottom:8px;font-size:.9em}@media (max-width:767px){.article-list h3{padding-top:10px}.article-list .article-list-actions{bottom:10px;right:10px}}.article-byline{display:inline-block;background:#fafafa;border:1px solid #e6e6e6;color:#aaa;font-size:.9em;padding:3px 10px}.article-thumbnail{margin:0 0 20px 20px}.article-content{text-align:justify}.article-widget-listing h3{margin-top:5px}table.event-table th{background:#f5f5f5}


#carousel-home .carousel-inner img {
    margin-left: 0 !important;
}
